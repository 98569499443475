
          $env: 'production';
          @import '/var/build/src/shared/styles/_utils';
          @import '/var/build/src/assets/styles/_utils-customer';
        





























.panel-comparison {
  // overflow-x: hidden;
  // overflow-y: auto;
  width: 100%;
  max-width: 70rem;
  min-height: 100%;
  padding: $spacing;
  background: $c-light;

  @include mq($until: m) {
    padding-top: $spacing * 3;

    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      display: block;
      width: 100%;
      height: $spacing * 4;
      background-color: white;
    }
  }

  @include mq(l) {
    padding: $spacing * 2 $spacing * 6;
  }

  ::v-deep {
    .wysiwyg__content {
      width: auto;

      h3 {
        margin-top: 0;
      }
    }
  }
}
